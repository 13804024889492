<template>
  <div>
    <!-- details polisa start -->
    <v-dialog v-model="polisaDialog" width="99%">
      <v-card>
        <v-card-title class="headline">
          <!-- header  -->
          <v-row black class="d-block" dense style="width: 100% !important">
            <v-col cols="12">
              <v-tabs fixed-tabs next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows center-active background-color="orange accent-1" color="black" class="rounded-lg" light>
                <v-tab> جميع البوالص </v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <!-- polisas  -->
          <v-row class="text-center">
            <v-col cols="12">
              <ShipmentsPrintbar :trip="selectedTrip" :shipments="tripShipments" :numbers="shipmentsNumbers" />
              <v-col style="margin-top: 20px">
                <ShipmentsTable v-model="tripShipments" />
              </v-col>
            </v-col>
          </v-row>
          <!-- trip  -->
        </v-card-title>
      </v-card>
    </v-dialog>

    <!-- finished polisa  -->
    <v-dialog v-model="dialogTripCity" width="500" persistent>
      <v-card>
        <v-form v-model="valid" ref="addTripForm">
          <v-card-title class="
                  headline
                  yellow
                  black--text
                  text-capitalize text-center
                  lighten-2
                ">ادخل بيانات الرحلة</v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mb-3" dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete :rules="[allRules.required]" dense :disabled="showTripInfo" outlined label="اسم السائق"
                    :items="drivers" item-value="id" item-text="name" auto-select-first :filter="driverFilter"
                    v-model="selected.driverId">
                  </v-autocomplete>
                </v-col>
                <!-- 3-cars -->
                <v-col cols="12" sm="12">
                  <v-autocomplete :rules="[allRules.required]" dense :disabled="showTripInfo" outlined label="السياره"
                    :items="cars" :filter="carFilter" item-value="id" item-text="plate" auto-select-first
                    v-model="selected.carId">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="pink" @click="dialogTripCity = false">الغاء</v-btn>
            <v-btn text color="success" :disabled="!valid || submitted" @click="addCityTrip">تاكيد</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="passworDialog" width="500">
      <v-card>
        <v-card-title class="
                  headline
                  yellow
                  black--text
                  text-capitalize text-center
                  lighten-2
                ">ادخل الرقم السرى</v-card-title>
        <div class="ma-5">
          <v-text-field :rules="[allRules.required]" dense autocomplete="off" type="password" class="mb-3"
            v-model="password" outlined label="ادخل كود تسليم السائق"></v-text-field>
          <v-alert type="error" class="text-capitalize" outlined v-if="alert">{{
            alertMsg
          }}</v-alert>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="pink" @click="closePass()">الغاء</v-btn>
          <v-btn text color="success" @click="submitPassword(2)">تاكيد</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="pr-5 pb-3 mt-5" dense>
      <v-col cols="1">
        <v-btn v-can="'Trips.Create'" color="success" @click="add">
          اضافة رحلة
          <i class="fa fa-plus mr-2"></i>
        </v-btn>
      </v-col>
      <!--<v-col cols="3">
        <v-btn color="success" @click="addTripCity">
          اضافة رحلة رجيع
          <i class="fa fa-plus mr-2"></i>
        </v-btn>

       
        <hr />

       
       </v-col>  -->
    </v-row>
    <v-card min-width="98%" class="ma-4">
      <v-data-table v-can="'Trips.View'" :headers="headers" :items="entities" :options.sync="options" fixed-header
        :server-items-length="total" :search="search" :items-per-page="20" :loading="loadingStates.table"
        class="elevation-1" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-plus',
          nextIcon: 'mdi-minus',
          'items-per-page-text': 'عدد الرحلات ' + total + '',
        }">
        <!-- end the expand -->

        <template v-slot:[`item.print`]="{ item }">
          <v-btn v-can="'Trips.View'" color="blue" outlined elevation="2" class="mx-2" @click="openTrip(item)" icon>
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-btn v-if="item.movementState == 2" v-can="'Trips.View'" color="orange" outlined elevation="2" class="mx-2"
            @click="viewTrip(item)" icon>
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:top>
          <v-row class="mx-auto mt-3 pa-5" dark dense>
            <v-col cols="12" sm="2">
              <v-text-field hide-details dense outlined type="number" v-model="options.trackingNumber"
                label="رقم الرحلة"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.date = $event" :date.sync="options.date" label="التاريخ ">
              </DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.departure = $event" :date.sync="options.departure" label="تاريخ المغادرة">
              </DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.arrive = $event" :date.sync="options.arrive" label="تاريخ الوصول">
              </DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined label="نوع الخدمه" :items="serviceTypes" item-value="id" item-text="name"
                auto-select-first v-model="options.serviceTypeId">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined label="السياره" :items="cars" :filter="carFilter" item-value="id"
                item-text="plate" auto-select-first v-model="options.carId">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined label="اسم السائق" :items="drivers" item-value="id" item-text="name"
                auto-select-first :filter="driverFilter" v-model="options.driverId">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense hide-details v-model="options.state" outlined :items="states" item-text="text"
                item-value="value" label="الحالة"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined hide-details auto-select-first label="المسار" :items="lines" item-value="id"
                item-text="name" v-model="options.lineId"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined hide-details auto-select-first label="حالة الوصول / المغادرة"
                :items="movementTypes" item-value="value" item-text="text" v-model="options.movementState">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete dense outlined hide-details label="حركة الرحلات" :items="reporttype" item-value="id"
                item-text="name" v-model="options.reportTypeId"></v-autocomplete></v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.dateFrom = $event" :date.sync="options.dateFrom" label="التاريخ من ">
              </DatePicker-layout>
            </v-col>
            <v-col cols="12" sm="2">
              <DatePicker-layout @myEvent="options.dateTo = $event" :date.sync="options.dateTo" label="التاريخ الى ">
              </DatePicker-layout>
            </v-col>
            <v-col >
              <!-- <v-card-actions> -->
              <!-- <v-btn @click="searchFortrips" class="mx-3" color="success"
                >بحث
                <v-icon>mdi-magnify</v-icon>
              </v-btn>-->
              <v-btn color="error" @click="resetOptions">تفريغ
                <i class="fa fa-trash fa-lg"></i>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <AppStateTrips :state="item.state" :options="states" :optionsChange="statesChange" @click="selected = item"
            @changeState="changeState(item, $event)" />
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | ToDate }}
        </template>
        <template v-slot:[`item.departure`]="{ item }">
          <div v-if="item.departure">
            <span>
              <v-chip color="green lighten-4" label>{{ item.departure | ToDate }}
              </v-chip>
            </span>
            {{ formateDateWithMoment(item.departure, 'LT') }}
          </div>
        </template>
        <template v-slot:[`item.arrive`]="{ item }">
          <div v-if="item.arrive">
            <span>
              <v-chip color="green lighten-4" label>{{ item.arrive | ToDate }}
              </v-chip>
            </span>
            {{ formateDateWithMoment(item.arrive, 'LT') }}
          </div>
        </template>
        <template v-slot:[`item.line.name`]="{ item }">
          <div v-if="item.line">
            <span>
              <v-chip color="green lighten-4" label>{{ item.line.name }}
              </v-chip>
            </span>
          </div>
        </template>
        <template v-slot:[`item.car`]="{ item }">
          <div v-if="item.car">
            <span class="ml-2">
              <v-chip label small>{{ item.car.mark }} </v-chip>
            </span>
            <span>{{ item.car.plate }} </span>
          </div>
          <div v-if="item.driver">
            <v-icon color="orange darken-1" small class="ml-2">
              fas fa-user-alt
            </v-icon>
            {{ item.driver.name }}
          </div>
        </template>
        <template v-can="'Trips.Update'" v-slot:[`item.movementState`]="{ item }">
          <div>
            <!-- update buttons  start-->
            <v-row>
              <v-col cols="1">
                <div>
                  <v-menu>
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn :color="getColor(item)" v-bind="attrs" v-on="{ ...tooltip, ...menu }" elevation="2" icon>
                            <v-icon>mdi-truck</v-icon>
                          </v-btn>
                        </template>
                        <span>تغير وصول/مغادرة الرحلة</span>
                      </v-tooltip>
                    </template>
                    <v-card class="text-center" width="200">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h5">الحالة الحالية</v-list-item-title>
                            <v-divider></v-divider>
                            <v-list-item-subtitle v-for="(opt, index) in movement.filter(
                              (e) => e.value == item.movementState
                            )" :key="index" style="margin: 20px 20px 0px 0px">
                              <v-chip label outlined :color="opt.color">
                                <v-icon :color="opt.color">{{
                                  opt.icon
                                }}</v-icon>{{ opt.text }}
                              </v-chip>
                            </v-list-item-subtitle>
                            <v-divider></v-divider>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item-action class="mt-0" style="margin: 20px">
                          <v-list-item-title class="m-1" v-if="item.movementState == 0">
                            <v-chip label outlined color="blue" style="cursor: pointer"
                              @click="changeMovement(item, 1, 0)">
                              <v-icon color="blue">mdi-arrow-up</v-icon>تم
                              مغادرة الرحلة
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-title class="m-1" v-if="item.movementState == 1">
                            <v-chip label outlined color="green" style="cursor: pointer"
                              @click="changeMovement(item, 2, 0)">
                              <v-icon color="green">mdi-arrow-down</v-icon>تم
                              وصول الرحلة
                            </v-chip>
                          </v-list-item-title>
                        </v-list-item-action>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <!-- update buttons  end -->
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-can="'Trips.Delete'" color="red" class="ml-2" icon elevation="2" outlined @click="del(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn v-can="'Trips.Update'" color="primary" elevation="2" icon @click="edit(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.ِchangebook`]="{ item }">
          <v-btn v-can="'Trips.Update'" color="primary" elevation="2" icon @click="openBookDialog(item.id)">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- Add Modal -->
    <v-dialog v-model="dialog" width="80%" persistent>
      <v-card>
        <v-form v-model="valid" ref="addTripForm">
          <v-card-title class="headline">
            {{ dialogTitle }}
            <!-- <i class="fa fa-pen ml-2" ></i> تعديل/إضافة  -->
          </v-card-title>
          <v-card-text>
            <v-row v-if="showTripInfo">
              <span> تم فتح الرحلة بواسطة : </span>
              <v-col cols="3">
                <AppLabel label="الموظف" color="green lighten-3" v-if="selected.createdBy"
                  :value="selected.createdBy.name" />
              </v-col>
              <v-col cols="4">
                <AppLabel label="التاريخ" :value="selected.createDate | ToDate" />
              </v-col>
              <v-col cols="3">
                <AppLabel label="الساعه" :value="selected.createDate | ToTime" />
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="showTripInfo">
              <span>أخر تعديل تم بواسطة : </span>
              <v-col cols="3">
                <AppLabel label="الموظف" color="green lighten-3" v-if="selected.updatedBy"
                  :value="selected.updatedBy.name" />
              </v-col>
              <v-col cols="4">
                <AppLabel label="التاريخ" :value="selected.updateDate | ToDate" />
              </v-col>
              <v-col cols="3">
                <AppLabel label="الساعه" :value="selected.updateDate | ToTime" />
              </v-col>
            </v-row>
            <v-container>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="4">
                  <v-text-field dense :disabled="showTripInfo" outlined label="الرقم اليدوى" type="number"
                    v-model="selected.manualNo">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-text-field dense :disabled="showTripInfo" outlined label="قيمه الايجار" type="number"
                    v-model="selected.rentAmount">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field dense :disabled="showTripInfo" outlined label="اجمالى مصاريف" type="number"
                    v-model="selected.totalExpenses">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-autocomplete :rules="[allRules.required]" dense :disabled="showTripInfo" outlined label="نوع الخدمه"
                    :items="serviceTypes" item-value="id" item-text="name" auto-select-first
                    v-model="selected.serviceTypeId">
                  </v-autocomplete>
                </v-col>
                <!-- 2-driver -->

                <v-col cols="12" sm="6">
                  <v-autocomplete :rules="[allRules.required]" dense :disabled="showTripInfo" outlined label="اسم السائق"
                    :items="drivers" item-value="id" item-text="name" auto-select-first :filter="driverFilter"
                    v-model="selected.driverId">
                  </v-autocomplete>
                </v-col>
                <!-- 3-cars -->
                <v-col cols="12" sm="6">
                  <v-autocomplete :rules="[allRules.required]" dense :disabled="showTripInfo" outlined label="السياره"
                    :items="cars" :filter="carFilter" item-value="id" item-text="plate" auto-select-first
                    v-model="selected.carId">
                  </v-autocomplete>
                </v-col>
                <!-- 4-lines -->
                <v-col cols="12" sm="6" v-if="dialogTitle == 'تعديل'">
                  <v-autocomplete :rules="[allRules.required]" dense :disabled="showTripInfo" outlined label="المسار"
                    :items="lines" item-value="id" item-text="name" auto-select-first v-model="selected.lineId">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" v-else>
                  <v-autocomplete :rules="[allRules.required]" :disabled="showTripInfo" dense outlined chips clearable
                    deletable-chips multiple small-chips label="المسار" :items="lines" item-text="name" return-object
                    auto-select-first v-model="selected.line">
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="my-0" dense>
                <v-col cols="12" sm="6">
                  <DatePicker-layout @myEvent="selected.date = $event" :disabled="showTripInfo"
                    :date="selected.date | ToDate" :rules="[allRules.required]" label="التاريخ"></DatePicker-layout>
                </v-col>
                <v-col cols="12" sm="6">
                  <TimePicker-layout :disabled="showTripInfo" label="وقت المغادره"></TimePicker-layout>
                </v-col>
                <v-col cols="12" sm="6">
                  <TimePicker-layout :disabled="showTripInfo" label="وقت الوصول"></TimePicker-layout>
                </v-col>
                <v-col cols="12" sm="6">
                  <TimePicker-layout :disabled="showTripInfo" label="وقت الوصول الفعلى"></TimePicker-layout>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-col cols="12" sm="6">
                  <v-radio-group v-model="selected.tripCostType">
                    <v-radio :disabled="showTripInfo" v-for="(n, i) in renting" mandtory :key="i" :label="`${n.text}`"
                      :value="n.value"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row class="my-0 ammar" dense>
                <v-textarea outlined :disabled="showTripInfo" name="input-7-1" label="ألملاحظات"
                  v-model="selected.notice"></v-textarea>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="!showTripInfo" :disabled="!valid || submitted" @click="editConfirmed()">
              حفظ
            </v-btn>

            <v-btn color="red darken-1" text @click="cancelEdit()">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Add Modal -->
    <v-dialog v-model="dialogChangeBook" width="400" persistent>
      <v-card>
        <v-form v-model="valid">
          <v-card-title class="headline yellow black--text text-capitalize text-center lighten-2">نقل بوالص دفتر
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mb-3" dense>
                <v-col cols="12" sm="6">
                  <v-text-field hide-details dense outlined type="number" v-model="bookChange.manualNoFrom"
                    label="يدوي من"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field hide-details dense outlined type="number" v-model="bookChange.manualNoTo" label="الي">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="pink" @click="dialogChangeBook = false">الغاء</v-btn>
            <v-btn text color="success" :disabled="!valid || submitted" @click="changeBookTrip">تاكيد</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "../services/TripsService.js";
import ShipmentService from "../services/ShipmentService.js";
import ServiceTypesService from "../../Settings/services/ServiceTypesService";
import CarsService from "../services/CarService";
import DriverService from "../services/DriverService";
import Enums from "./../../../plugins/enums";
import LinesService from "../services/LinesService.js";

export default {
  data() {
    return {
      valid: false,
      submitted: false,
      passworDialog: false,
      password: null,
      itemId: null,
      states: Enums.TripStates,
      statesChange: Enums.TripStatesChange,
      movement: Enums.TripMovement,
      movementTypes: Enums.TripMovementTypes,
      selectedTrip: {},
      tripShipments: null,
      shipmentsNumbers: [],
      alert: false,
      alertMsg: null,
      dialogTitle: "",
      polisaDialog: false,
      renting: Enums.TripCostType,
      selected: {
        state: "",
        departure: this.formatDate(Date.now()),
        arrive: this.formatDate(Date.now()),
        actualArrive: "11:15",
        date: this.formatDate(Date.now()),
      },
      reporttype: [
        { id: 2, name: "حركة الرحلات الصادرة" },
        { id: 3, name: "حركة رحلات الرجيع" },
      ],
      drivers: [],
      cars: [],
      lines: [],
      serviceTypes: [],
      showTripInfo: false,
      entities: [],
      total: 0,
      search: "",
      options: {
        trackingNumber: null,
        serviceTypeId: null,
        driverId: null,
        carId: null,
        lineId: null,
        date: null,
        departure: null,
        arrive: null,
        state: null,
        dateFrom: null,
        dateTo: null,
        movementState: null,
        reportTypeId: null,
      },
      bookChange: {
        tripId: null,
        manualNoFrom: null,
        manualNoTo: null,
      },
      dialog: false,
      dialogChangeBook: false,
      dialogTripCity: false,
      loadingStates: {
        table: false,
      },
    };
  },
  created() {
    this.getData();
    DriverService.typeHead("", true, "").then((resp) => {
      this.drivers = resp.data;
    });
    CarsService.typeHead("", true).then((resp) => {
      this.cars = resp.data;
    });
    ServiceTypesService.typeHead("", true).then((resp) => {
      this.serviceTypes = resp.data;
    });
    LinesService.typeHead("", false, false, true).then((resp) => {
      this.lines = resp.data;
    });
  },

  methods: {
    openBookDialog(id) {
      this.bookChange.tripId = id;
      this.dialogChangeBook = true;
    },
    changeBookTrip() {
      var model = {
        TripId: this.bookChange.tripId,
        ManualNoFrom: this.bookChange.manualNoFrom,
        ManualNoTo: this.bookChange.manualNoTo
      };
      ShipmentService.changeBookTrip(model)
        .then(resp => {
          this.$dialog.notify.success("تم نقل البوالص بنجاح", {
            position: "top-right",
            timeout: 3000,
          });
          this.getData();
        })
        .catch(err => {
          this.$dialog.notify.error(err.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        });
      this.dialogChangeBook = false;
      this.resetOptions();
    },
    searchFortrips() {
      this.getData();
    },
    resetOptions() {
      this.bookChange = {
        tripId: null,
        manualNoFrom: null,
        manualNoTo: null,
      };

      this.options = {
        trackingNumber: null,
        serviceTypeId: null,
        driverId: null,
        carId: null,
        lineId: null,
        date: null,
        departure: null,
        arrive: null,
        state: null,
        dateFrom: null,
        dateTo: null,
        movementState: null,
        reportTypeId: null,
      };
    },
    carFilter(item, queryText, itemText) {
      return (
        item.id.toString().includes(queryText) || item.plate.includes(queryText)
      );
    },
    driverFilter(item, queryText, itemText) {
      return (
        item.id.toString().includes(queryText) || item.name.includes(queryText)
      );
    },
    viewTrip(trip) {
      this.showTripInfo = true;
      this.selected = { ...this.entities.filter((e) => e.id == trip.id)[0] };
      this.dialog = true;
      this.dialogTitle = "عرض تفاصيل الرحلة";
    },
    openTrip(trip) {
      this.selectedTrip = trip;
      this.showShipments();
      this.polisaDialog = true;
    },
    closePass() {
      this.password = null;
      this.passworDialog = false;
    },
    showShipments() {
      this.showTripInfo = false;
      ShipmentService.whatsAppSent(this.selectedTrip.id)
        .then((resp) => {
          this.shipmentsNumbers = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
      ShipmentService.byTripId(this.selectedTrip.id)
        .then((resp) => {
          this.tripShipments = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeState(item, state) {
      ApiService.updateState(item.id, state)
        .then((resp) => {
          item.state = state;
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    changeMovement(item, movement, code) {
      ApiService.updateMovement(item.id, movement, code)
        .then((resp) => {
          item.movementState = movement;
          if (movement == 1) {
            this.$dialog.notify.success("تم مغادره الرحله", {
              position: "top-right",
              timeout: 3000,
            });
          }
          else {
            this.$dialog.notify.success("تم وصول الرحله", {
              position: "top-right",
              timeout: 3000,
            });
            //this.closePass();
            //this.getData();
          }
        })
        .catch((resp) => {
          this.$dialog.notify.error(resp.request.response, {
            position: "top-right",
            timeout: 3000,
          });
          //this.closePass();
          //this.getData();
        })
        .finally(() => { });
    },
    getColor(item) {
      switch (item.movementState) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "red";
      }
    },
    getData() {
      this.loadingStates.table = true;
      if (this.options) this.options.searchTerm = this.search;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          console.log(resp.data.items);
          this.entities.forEach((i) => {
            ApiService.setEntity(i);
          });
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
        });
    },
    submitPassword(type, item) {
      if (type == 1) {
        this.itemId = item.id;
        this.passworDialog = true;
      }
      else {
        this.changeMovement(this.itemId, 2, this.password);
      }
    },
    editConfirmed() {
      if (!this.submitted) {
        this.submitted = true;
        ApiService.edit(this.selected)
          .then((resp) => {
            this.dialog = false;
            var message = "تم تعديل الرحلة بنجاح";
            if (!this.selected.id) {
              this.$set(
                this.entities,
                this.entities.length,
                ApiService.setEntity(resp.data)
              );
              message = "تم إضافة الرحلة بنجاح";
              this.selected.departure = null;
              this.selected.arrive = null;
              this.selected.actualArrive = null;
              this.$dialog.notify.success(message, {
                position: "top-right",
                timeout: 3000,
              });
            } else {
              this.updateArr(this.entities, ApiService.setEntity(resp.data));
              this.$dialog.notify.success(message, {
                position: "top-right",
                timeout: 3000,
              });
            }
            this.submitted = false;
          })
          .catch((err) => {
            this.dialog = false;
            console.log(err);
            this.$dialog.notify.error(err.request.response, {
              position: "top-right",
              timeout: 3000,
            });
            this.submitted = false;
          });
        this.$refs.addTripForm.resetValidation();
        this.resetOptions();
      }
    },
    addCityTrip() {
      if (!this.submitted) {
        this.submitted = true;
        ApiService.addtripCity(this.selected)
          .then((resp) => {
            this.dialogTripCity = false;
            var message = "تم إضافة الرحلة بنجاح";
            this.$set(
              this.entities,
              this.entities.length,
              ApiService.setEntity(resp.data)
            );
            this.selected.departure = null;
            this.selected.arrive = null;
            this.selected.actualArrive = null;
            this.$dialog.notify.success(message, {
              position: "top-right",
              timeout: 3000,
            });
            this.submitted = false;
          })
          .catch((err) => {
            this.dialogTripCity = false;
            console.log(err.request.response);
            this.$dialog.notify.error(err.request.response, {
              position: "top-right",
              timeout: 3000,
            });
            this.submitted = false;
          });
        this.selected = {};
        this.$refs.addTripForm.resetValidation();
      }
    },
    cancelEdit() {
      this.showTripInfo = false;
      this.dialog = false;
      this.selected = {};
    },
    addTripCity() {
      this.dialogTripCity = true;
    },
    add() {
      (this.selected = {
        state: "",
        departure: "",
        arrive: "",
        actualArrive: "",
        date: this.formatDate(Date.now()),
      }),
        (this.dialog = true);
      this.selected.state = 1;
      this.dialogTitle = "اضافه";
    },
    edit(id) {
      this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      this.dialog = true;
      this.dialogTitle = "تعديل";
    },
    del(id) {
      this.$dialog
        .info({
          text: "هل أنت متاكد من حذف الرحلة",
          title: "  حذف رحلة  ",
          persistent: true,
          actions: {
            true: {
              text: "نعم",
              color: "green",
              handle: () => {
                ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("تم مسح الرحلة بنجاح", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("خطأ اثناء مسح الرحلة", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
            false: {
              text: "الغاء",
            },
          },
        })
        .then((res) => { });
    },
    view(id) { },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },

  computed: {
    showState() {
      let curentState;
      if (this.selected.state == 1) {
        curentState = {
          state: "مفتوح",
          color: "green--text",
          icon: "mdi-lock-open-variant",
        };
      } else if (this.selected.state == 2) {
        curentState = {
          state: "مغلق",
          color: "red--text",
          icon: "mdi-lock",
        };
      } else if (this.selected.state == 3) {
        curentState = {
          state: "مرحل",
          color: "orange--text",
          icon: "mdi-lock-clock",
        };
      } else {
        curentState = {
          state: "اختار الحالة",
          color: "black--text",
          icon: "mdi-lock-clock",
        };
      }
      return curentState;
    },
    headers() {
      let headers = [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "trackingNumber",
        },
        {
          sortable: false,
          text: "التاريخ",
          value: "date",
          align: "center",
          width: "15%",
        },
        {
          sortable: false,
          text: "السياره",
          value: "car",
          align: "start",
          width: "40%",
        },
        {
          sortable: false,
          text: "المسار",
          value: "line.name",
          align: "center",
        },
        { sortable: false, text: "الحالة", value: "state", align: "center" },
        {
          sortable: false,
          text: "نقدى",
          value: "cashShipmentsPrice",
          align: "center",
        },
        {
          sortable: false,
          text: "ع.ت",
          value: "cashOnDeliveryShipmentsPrice",
          align: "center",
        },
        {
          sortable: false,
          text: "تاريخ المغادرة",
          value: "departure",
          align: "center",
          width: "15%",
        },
        {
          sortable: false,
          text: "تاريخ الوصول",
          value: "arrive",
          align: "center",
          width: "15%",
        },
        {
          sortable: false,
          text: "التفاصيل ",
          value: "details",
          align: "center",
          width: "5%",
        },
        {
          sortable: false,
          text: "الطباعة ",
          value: "print",
          align: "center",
          width: "5%",
        },
        {
          sortable: false,
          text: "وصول / مغادرة ",
          value: "movementState",
          align: "center",
          width: "5%",
        },
        {
          sortable: false,
          text: "نقل بوالص دفتر ",
          value: "ِchangebook",
          align: "center",
          width: "5%",
        },
        {
          sortable: false,
          text: "تعديل / مسح",
          value: "actions",
          align: "center",
          width: "25%",
        },
      ];
      return headers;
    },
  },
};
</script>

<style lang="scss" scoped>
.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

.hiddenradio [type="radio"]+div {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

/* CHECKED STYLES */
.hiddenradio [type="radio"]:checked+div {
  outline: 2px solid #42ac0d;
  outline-style: dashed;
}

.text-end {
  display: inline-table !important;
}
</style>